import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                nav: 'toggleNav',
                cart: 'toggleCart',
            }
        }
    }

    init() {

    }

    toggleNav(e) {
        //const burger = this.$('nav')[0];
        const html = document.querySelector('html');
        if(html.classList.contains('has-navOpen')) {
            html.classList.remove('has-navOpen');
            //burger.classList.remove('is-active');
        } else {
            html.classList.add('has-navOpen');
            //burger.classList.add('is-active');
        }
    }
}
