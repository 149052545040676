import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            galerie: 'toggleImage',
          },
          mousemove: {
            zoom: 'zoom',
          },
        }
    }

    init() {
      jQuery('input[type="radio"]').change(function() {
        jQuery(this).parents('form').submit();
      });
    }

    toggleImage(e) {
      const currentTarget = e.currentTarget;
      const src = currentTarget.getAttribute('src');
      const alt = currentTarget.getAttribute('alt');
      const target = document.querySelector('.b-single_main-image img');
      const targetZoom = document.querySelector('#large-img');
      target.setAttribute('src', src);
      target.setAttribute('alt', alt);
      targetZoom.style.backgroundImage = "url('"+ src +"')";
      const sections = this.$('section');
      sections.forEach(element => element.classList.remove('is-active'));
      currentTarget.closest('.b-single_images_thumbnail').classList.add('is-active');
    }

    zoom(e) {
      let original = document.getElementById('main-img'),
      magnified = document.getElementById('large-img'),
      container = document.querySelector('.b-single_main-image'),
      style = magnified.style,
      x = e.pageX - container.offsetLeft,
      y = e.pageY - container.offsetTop,
      imgWidth = original.width,
      imgHeight = original.height,
      xperc = (x / imgWidth) * 100,
      yperc = (y / imgHeight) * 100;

    // Add some margin for right edge
    if (x > 0.01 * imgWidth) {
      xperc += 0.15 * xperc;
    }

    // Add some margin for bottom edge
    if (y >= 0.01 * imgHeight) {
      yperc += 0.15 * yperc;
    }

    // Set the background of the magnified image horizontal
    style.backgroundPositionX = xperc - 9 + '%';
    // Set the background of the magnified image vertical
    style.backgroundPositionY = yperc - 9 + '%';

    // Move the magnifying glass with the mouse movement.
    style.left = x - 75 + 'px';
    style.top = y - 75 + 'px';
  }
}
