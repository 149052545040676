import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          change: {
            toggleInput: 'toggleInput',
          },
          focusin: {
            input: 'active',
          },
          focusout: {
            input: 'desactive',
          }
        }
    }

    init() {

    }

    active(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      item.classList.add('is-active');
    }

    desactive(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      if(target.value == "") {
        item.classList.remove('is-active');
      }
    }

    toggleInput(e) {
      const current = e.currentTarget;
      var target = $(current).attr('data-toggle');
      $(target).toggleClass('c-form_hide');
    }
}
