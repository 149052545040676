import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      var $position = 0;
      var $header_scroll = jQuery('.c-header_scroll');
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: true
        });

        var scroller = this.scroll;

        setTimeout(() => {
          var header_height = $header_scroll.outerHeight();
          jQuery('main').css('padding-top', header_height + 'px');
          //jQuery('.b-intro-slider-images .swiper-wrapper').css('height', 'calc(100vh - '+ header_height + 'px');
          //jQuery('.c-nav').css('top', header_height + 'px');
          this.scroll.update();
        }, 500);

        $(window).resize(function() {
          setTimeout(() => {
            var header_height = $header_scroll.outerHeight();
            jQuery('main').css('padding-top', header_height + 'px');
            //jQuery('.b-intro-slider-images .swiper-wrapper').css('height', 'calc(100vh - '+ header_height + 'px');
            //jQuery('.c-nav').css('top', header_height + 'px');
            scroller.update();
          }, 100);
        });

        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], { way, obj }, func[1], func[2]);
        });

        this.scroll.on('scroll', (args) => {
            // console.log(args.scroll);

            if($position > args.scroll.y && args.scroll.y > $header_scroll.outerHeight()) {
              $header_scroll.addClass('is-active');
            }else {
              if(args.scroll.y > $header_scroll.outerHeight()) {
                $header_scroll.removeClass('is-active');
              }
            }
            $position = args.scroll.y;
        })
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if (src.length) {
            if (args.obj.el.tagName === 'IMG') {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url('${src}')`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    destroy() {
        this.scroll.destroy();
    }

    scrollTo(target) {
      this.scroll.scrollTo(target);
    }

    update() {
      this.scroll.update();
    }
}
