import { module } from 'modujs';
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Thumbs } from 'swiper/core';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, Thumbs]);

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      var container = '.c-slider_container';
      var items = 1;
      var pagination = false;
      var navigation = false;
      var thumbs = false;
      var thumbsContainer = '.c-slider_thumbs';
      var breakpoints = [];

      if (this.getData('container')) {
        container = this.getData('container');
      }

      if (this.getData('items')) {
        items = this.getData('items');
      }

      if (this.getData('pagination')) {
        pagination = {
          el: this.getData('pagination') + '-pagination',
          clickable: true,
        };
      }

      if (this.getData('navigation')) {
        navigation = {
          nextEl: this.getData('navigation') + '-next',
          prevEl: this.getData('navigation') + '-prev',
        };
      }

      if (this.getData('breakpoints')) {
        breakpoints = JSON.parse(this.getData('breakpoints'));
      }

      if(this.getData('thumbs')) {
        thumbsContainer = this.getData('thumbs');
        var thumbsInstance = new Swiper(thumbsContainer, {
          spaceBetween: 10,
          freeMode: false,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          slidesPerView: 6,
          breakpoints: {
            0: {
              slidesPerView: 3,
            },
            700: {
              slidesPerView: 4,
            },
            1000: {
              slidesPerView: 6,
            }
          }
        });
        thumbs = {
          swiper: thumbsInstance,
        };
      }

      var gallery = new Swiper(container, {
        slidesPerView: parseInt(items),
        // Optional parameters
        direction: 'horizontal',
        //loop: true,
        autoHeight: false,

        // If we need pagination
        pagination: pagination,

        // Navigation arrows
        navigation: navigation,

        breakpoints: breakpoints,

        thumbs: thumbs,

        // LazyLoading
        preloadImages: false, // Disable preloading of all images
        lazy: true, // Enable lazy loading
      });

    }
}
